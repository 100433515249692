<header *ngIf="visible">
  <div class="row" style="margin: unset!important;    background-color: #2B2E86 !important;
  font-family: 'Poppins', sans-serif;
  color: white !important;">
    <div class="col-md-6">
    </div>
    <div class="col-md-6">
      <div class="row" style=" float: right; padding-right: 20px;">
        <div class="row">

          <span style="padding-right: 20px;">
          <img src="assets/new/newdesign/images/Iconmaterial-email.svg" style="width: 25px;"> commercial@telemex.cm
          </span>
          <span style="">
            <a href="https://wa.me/+237655767535"><img src="assets/new/newdesign/images/WhatsApp.png" style="width: 25px;"/></a>
          </span>
          <span style="padding-right: 20px;">
            +237 655 767 535
          </span>
        </div>
      </div>
    </div>
  </div>
  <nav class="navbar navbar-expand-lg navbar-light ">
    <a class="navbar-brand" href="https://telemexcm.com/index.php">
      <img src="assets/new/newdesign/images/logo.png" alt="Logo">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse"
      style="z-index: 1;background-color: #2B2E86!important;padding: 15px;">
      <ul class="navbar-nav ml-auto" style="width: 100%;">
        <!-- <li class="nav-item">
        <a class="nav-link" href="chargement-new.php">Envoyer un colis</a>
      </li> -->
        <li class="nav-item">
          <a class="nav-link" href="https://telemexcm.com/inscription1.php">Nous rejoindre</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://telemex.suivi.telefret.com/">Suivi</a>
        </li>
        <!-- <li class="nav-item">
        <a class="nav-link"  href="historique-new.php?telephone=">Historique</a>
</li>
       -->


        <!-- <li class="nav-item nav-itemCustom1">
          <a class="nav-link" href="login-new.php"><img src="assets/new/newdesign/images/ic_account_circle_24px.svg"><button class="rounded-button" style="margin-left: 20px;padding: 5px!important;">Se connecter ></button></a>
        </li>
        <li class="nav-item nav-itemCustom1 row">
          <a class="nav-link" href="mainindex.php" 
  style="display: block;
  padding: 0.5rem 1rem;">
    <a class="nav-link" href="profile.php" 
  style="display: block;
  padding: 0.5rem 1rem;">
          <img src="assets/new/newdesign/images/ic_account_circle_24px.svg" />  </a><a class="nav-link" href="logout.php" >  |  <span style="color: red!important;margin-left: 10px;"> Déconnexion </span> </a>
        </li> -->
      </ul>
    </div>
  </nav>
</header>




<link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/ol3/3.5.0/ol.min.css" type="text/css">
<ng-template [ngIf]="visible">
  
  <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" style="    background-image: url(https://telemex.suivi.telefret.com/assets/new/newdesign/images/slider/1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;">
   
    <div class="carousel-inner" >
    </div>
    <div class="e1_598">
      <div class="bradcam_area bradcam_bg_1" [style.padding]=" details &&!details[0]?' 182px 0 102px':'20px'"
      style="text-align: -webkit-center;padding: 20px;">
      <div class="col-md-7" *ngIf="false" style="  
        background-image: linear-gradient(rgb(255 255 255 / 50%), rgb(255 255 255 / 50%)), url(https://telemexcm.com/assets/img/logo.png);
      background-repeat: no-repeat;
      background-position: center;
    background-color: white;
    filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
    margin: 25px;
    border-radius: 10px;
    text-align: start;
    border: 1px solid #cccccc;
    bottom: 12px;
    min-width: 280px;margin-left: auto!important;margin-right: auto!important; ">
  
        <div class="row">
          <div class="col-md-6">
            <p style="color: black!important">Client:
              {{details[0].Chargement?.client?details[0]?.Chargement?.client:""
              }} </p>
            <p style="color: black!important">Téléphone:
              {{details[0].Chargement?.telephoneclient?details[0]?.Chargement?.telephoneclient:"" }} </p>
            <p style="color: black!important">Adresse:
              {{details[0].Chargement?.adresseclient?details[0]?.Chargement?.adresseclient:"" }} </p>
          </div>
          <div class="col-md-6">
            <p style="color: black!important">Transorteur: Socopat</p>
            <p style="color: black!important">Téléphone: </p>
            <p style="color: black!important">Adresse: </p>
          </div>
        </div>
        <div class="row" style="display: flex;
    justify-content: center;">
          <h6>Détails</h6>
        </div>
        <div class="row">
          <div class="col-md-6">
  
            <p style="color: black!important"> Type de marchandise:
              {{details[0].Chargement?.marchandise?details[0]?.Chargement?.marchandise:"" }} </p>
  
            <p style="color: black!important">Adresse de chargement : {{ details[0]?.Chargement?.adresse_charg+","+thisatob(details[0]?.Chargement?.ville_charg) }}</p>
            <p style="color: black!important">Date de chargement: {{ details[0]?.Chargement?.date_charg }}</p>
  
          </div>
          <div class="col-md-6">
            <p style="color: black!important">Adresse de livraison : {{ details[0]?.Chargement?.adresse_liv+","+ thisatob(details[0]?.Chargement?.ville_liv) }}</p>
            <p style="color: black!important">Date de livraison: {{ details[0]?.Chargement?.date_liv }}</p>
  
          </div>
        </div>
  
      </div>
      <div class="row">
  
  
        <!-- <div class="col-xl-12"
          style="text-align: -webkit-center;margin-left: auto!important;margin-right: auto!important;">
          <div class="text-align-center">
            <div class="input-group col-md-3" style="margin-left: auto!important;margin-right: auto!important;">
              <div class="col-md-12">
                <h5 for="" style="color: white;">Saisissez le N° d'expédition</h5>
              </div>
            </div>
            <div class="input-group col-md-3" style="margin-left: auto!important;margin-right: auto!important;">
              <div class="col-md-12">
                <button type="button" (click)="onChange(value,true);onChange(value,false);" style="
              position:absolute; right: 10px
              " class="btn btn-success">
                  <i class="fa fa-search my-float"></i>
                </button>
                <input [ngModel]="value" (ngModelChange)="onChangeValue($event)" type="search" id="form1"
                  class="form-control" />
  
              </div>
  
            </div>
          </div>
        </div> -->
      </div>
    </div>
      <span class="e1_286">Saisissez le N° d'expédition</span>

      <div class="e1_282">
        <input type="text" class="e1_282input search-input"[ngModel]="value" (ngModelChange)="onChangeValue($event)" type="search" id="form1" placeholder="Saisissez votre numéro de suivi">
        <span class="search-icon" id="searchButton" type="button" (click)="onChange(value,true);onChange(value,false);">
          <img src="assets/new/newdesign/images/Iconawesomsearch.svg">
        </span>
      </div>
    </div>
  </div>

</ng-template>
<div class="wrapper " style="width: 80%;
margin-left: 10%;
margin-top: 50px;margin-bottom: 150px;">

  <div id="map" class="{{!valueFind?'demo-area1':'demo-area'}} map"></div>

</div>

<div id="popup" class="ol-popup">
  <a href="#" id="popup-closer" class="ol-popup-closer"></a>
  <div id="popup-content"></div>
</div>


<div id="evaluation-modal" class="modal col-md-12">
  <div class="modal-content text-center notedivmodal">
  <div class="col-md-12" style="display: inherit;
  text-align: center;
  justify-content: center;">
  <div class="row">
    <span class="servicee1_364" style="color: #2B2E86!important;font-size: 35px;width:unset;">Noter le service
      <div class="e1_328" style="position: unset!important;"></div>
    </span>
  </div>
</div>
    <div class="stars">
      <span class="star" data-value="1">&#9733;</span>
      <span class="star" data-value="2">&#9733;</span>
      <span class="star" data-value="3">&#9733;</span>
      <span class="star" data-value="4">&#9733;</span>
      <span class="star" data-value="5">&#9733;</span>
    </div>
    <div class="row col-md-12">
    <div class="col-md-6">
    <button id="evaluate-confirm-btn" class="aboute1_362"
      style="color: white;background-color: red!important;margin-top: 20px; width: unset!important;padding-left: 10px; padding-right: 10px; height: 40px!important;">Confirm</button>
    </div>
    <div class="col-md-6">
    <button id="evaluate-cancel-btn" class="aboute1_362"
      style="color: white;background-color: red!important;margin-top: 20px; width: unset!important;padding-left: 10px; padding-right: 10px; height: 40px!important;float: right;">Annuler</button>
    </div>      </div>
  </div>
</div>


<div class="row justify-content-center" *ngIf="visible" style="font-family: Poppins;
    color: white;text-align: center;margin: unset; background-color: #E92C24;">
  <div class="e1_1749" style="margin-top: 20px">
    <div class="e1_1695">       
      <span class="e1_1605"style="margin: 10px"><a style="color: white!important;"href="https://www.facebook.com/profile.php?id=100091002866528&mibextid=ZbWKwL"><img style="margin-right: 10px" src="assets/new/newdesign/images/instagram.svg" alt="Image 1"> Instagram</a></span>
      <span class="e1_1606"style="margin: 10px"><a style="color: white!important;"href="https://www.facebook.com/profile.php?id=100091002866528&mibextid=ZbWKwL"><img style="margin-right: 10px"src="assets/new/newdesign/images/twitter.svg" alt="Image 1">Twitter</a></span>
      <span class="e1_1607"style="margin: 10px"><a style="color: white!important;"href="https://www.facebook.com/profile.php?id=100091002866528&mibextid=ZbWKwL"><img style="margin-right: 10px"src="assets/new/newdesign/images/facebook.svg" alt="Image 1">Facebook</a></span>
      <span class="e1_1608"style="margin: 10px"><a style="color: white!important;"href="https://www.facebook.com/profile.php?id=100091002866528&mibextid=ZbWKwL"><img style="margin-right: 10px"src="assets/new/newdesign/images/linkedin.svg" alt="Image 1">Linkedin</a></span>
      <span class="e1_1609"style="margin: 10px"><a style="color: white!important;"href="https://www.facebook.com/profile.php?id=100091002866528&mibextid=ZbWKwL"><img style="margin-right: 10px"src="assets/new/newdesign/images/skype.svg" alt="Image 1">Skype</a></span>
    </div>
    <div class="e1_1696" style="margin-top: 20px">
      <span class="e1_1618" style="cursor: pointer;" onclick="window.location.href = 'condition-new.php';">Conditions d'utilisation</span>
      <span class="e1_1619" style="margin: 20px;cursor: pointer;" onclick="window.location.href = 'politique.php';">Mention légale</span>
    </div>
  </div>
  <div class="col-md-12" style="text-align: center;margin-top: 40px;">
    <span class="">Copyright © 2023 TELEMEX</span>
  </div>
</div>
<a *ngIf="visible" href="https://wa.me/+237655767535" target="_blank" class="whatsapp-button center">
  <img src="assets/new/newdesign/images/WhatsApp.png" style="width: 35px;"/>
</a>
